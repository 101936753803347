import axios from "axios";
import { authHeaders } from "@/store/config";

const state = {
  serviceTerms: [],
  serviceTermsData: {},
};

const getters = {
  serviceTerms: (state) => state.serviceTerms,
  serviceTermsData: (state) => state.serviceTermsData,
};

const actions = {
  createServiceTerm({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/service-term", data, {
          headers: authHeaders(localStorage.getItem("access_token")),
        })
        .then((response) => {
          commit("addServiceTerm", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getAllServiceTerms({ commit }, data) {
    return new Promise((resolve, reject) => {
      // Check if 'data' is defined, and set 'search' to an empty string if not
      const search = (data && data.search) || ""; 
      const pageNo = (data && data.pageNo) || 1;  // Default to page 1 if not provided
      const pageSize = (data && data.pageSize) || 10; // Default to pageSize 10 if not provided
  
      axios
        .get("/service-term/all", {
          params: {
            search: search,  // Use the search value, defaulting to an empty string
            pageNo: pageNo,  // Use the page number value, defaulting to 1
            pageSize: pageSize,  // Use the page size value, defaulting to 10
          },
          headers: authHeaders(localStorage.getItem("access_token")),
        })
        .then((response) => {
          if (response.data) {
            commit("setServiceTerms", response.data.data);
            commit("setServiceTermsData", response.data);
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  
};

const mutations = {
  addServiceTerm(state, data) {
    state.serviceTerms.push(data);
  },
  setServiceTerms(state, data) {
    state.serviceTerms = data;
  },
  setServiceTermsData(state, data) {
    state.serviceTermsData = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
