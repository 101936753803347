// import router from '@/router';
import axios from "axios";
import { authHeaders } from "@/store/config";

const state = {
  employees: [],
  employees2: [],
  employeedata: {},
  roles: [],
};

const getters = {};

const actions = {
  createEmployee(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/employee/", data, {
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          context.commit("add_employee_obj", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateEmployee(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/employee/${data.id}`, data.employee, {
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          context.commit("add_employee_obj", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteEmployee(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/employee/${data.id}`, {
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          context.commit("add_employee_obj", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getAllEmployee(context, data) {
    return new Promise((resolve, reject) => {
      // Ensure 'data' is defined, and provide default values for 'search', 'pageNo', and 'pageSize'
      const search = (data && data.search) || "";  // Default search to empty string
      const pageNo = (data && data.pageNo) || 1;   // Default to page 1 if not provided
      const pageSize = (data && data.pageSize) || 10;  // Default to page size of 10 if not provided
  
      axios
        .get("/employee/all", {
          params: {
            search: search,
            pageNo: pageNo,
            pageSize: pageSize,
          },
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          if (response) {
            context.commit("set_employees", response.data.data);
            context.commit("set_data", response.data);
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  
  getAllEmployee2(context, data) {
    return new Promise((resolve, reject) => {
      // Ensure 'data' is defined, and provide default values for 'search', 'pageNo', and 'pageSize'
      const search = (data && data.search) || "";  // Default search to empty string
      const pageNo = (data && data.pageNo) || 1;   // Default to page 1 if not provided
      const pageSize = (data && data.pageSize) || 10;  // Default to page size of 10 if not provided
  
      axios
        .get("/employee/all", {
          params: {
            search: search,
            pageNo: pageNo,
            pageSize: pageSize,
          },
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          if (response) {
            context.commit("set_employees2", response.data.data);
            context.commit("set_data", response.data);
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  
  getRoles(context) {
    return new Promise((resolve, reject) => {
      axios
        .get("/roles/all", {
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          context.commit("set_roles", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  add_employee_obj(state, data) {
    state.employees.push(data);
  },
  set_employees(state, data) {
    state.employees = data;
  },
  set_employees2(state, data) {
    state.employees2 = data;
  },
  set_roles(state, data) {
    state.roles = data;
  },
  set_data(state, data) {
    state.employeedata = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
