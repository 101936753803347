import router from "@/router";
import axios from "axios";
import { defaultHeaders } from "@/store/config";

const state = {
  token: localStorage.getItem("access_token") || null,
  authStatus: false,
  verifyEmail: "",
  resetToken: localStorage.getItem("refresh_token") || null,
  user: {},
  currencies: [],
  plans: [],
  industries: [],
  selectedPlan: null,
  payment_url: "",
  pto: {},
};

const getters = {
  user: (state) => state.user,
};

const actions = {
  login(context, data) {
    // eslint-disable-next-line no-unused-vars
    const email = data.email;
    context.commit("set_verifyEmail", email);
    return new Promise((resolve, reject) => {
      axios
        .post("/org/login", data, {
          headers: defaultHeaders,
        })
        .then((response) => {
          if (response && response.status == 200) {
            const token = response.data.data.token;
            const refreshToken = response.data.data.refreshToken;
            context.commit("set_user", response.data.data.user);
            localStorage.setItem("access_token", token);
            localStorage.setItem("refresh_token", refreshToken);
            context.commit("set_token", token);
            context.commit("set_refreshToken", token);
            router.push({
              name: "Loading",
            });
          }
          let countryCurrency = [
            {
              symbol: "$",
              name: "US Dollar",
              symbol_native: "$",
              code: "USD",
              name_plural: "US dollars",
            },
          ];
          context.commit("set_currencies", countryCurrency);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  register(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/org/new", data, {
          headers: defaultHeaders,
        })
        .then((response) => {
          const paymentData = response.data.data.payment_data;
          if (paymentData && paymentData.url) {
            context.commit("set_url", paymentData.url);
          }
          const verifyEmail = response.data.data.organisation.email;
          context.commit("set_verifyEmail", verifyEmail);
          router.push({
            name: "Verification",
            params: {
              email: data.email,
            },
          });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getSubscriptionPlans(context) {
    return new Promise((resolve, reject) => {
      axios
        .get("/org/subscription-prices", { headers: defaultHeaders })
        .then((response) => {
          if (response) {
            context.commit("set_plans", response.data.data);
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getIndustries(context) {
    return new Promise((resolve, reject) => {
      axios
        .get("/industry/all", { headers: defaultHeaders })
        .then((response) => {
          if (response) {
            context.commit("set_industries", response.data.data);
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  confirmSubscription(context, { sessionId }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/org/confirm-payment?session_id=${sessionId}`, {
          headers: defaultHeaders,
        })
        .then((response) => {
          if (response) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  resendCode(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/org/resend-verification-account", data, {
          headers: defaultHeaders,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  verifyAccount(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          "/org/verify-account",
          {
            email: context.state.verifyEmail,
            code: data.code,
          },
          {
            headers: defaultHeaders,
          }
        )
        .then((response) => {
          if (context.state.payment_url) {
            window.open(context.state.payment_url, "_blank");
            // Set the payment_url to null after opening
            context.commit("set_url", null);
          }
          router.push({
            name: "Verified",
          });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  forgotPassword(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/org/request-password-reset", data, {
          headers: defaultHeaders,
        })
        .then((response) => {
          router.push({
            name: "ResetMail",
          });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  resetPassword(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `/org/reset-password/${data.token}`,
          {
            newPassword: data.password,
            confirmPassword: data.confirmPassword,
          },
          {
            headers: defaultHeaders,
          }
        )
        .then((response) => {
          router.push({
            name: "login",
          });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  createPassword(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/org/create-password`, data, {
          headers: defaultHeaders,
        })
        .then((response) => {
          router.push({
            name: "login",
          });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updateOrganization(context, { id, payload }) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/org/${id}`, payload, {
          headers: {
            ...defaultHeaders,
            Authorization: `Bearer ${context.state.token}`, // Pass token for authentication
          },
        })
        .then((response) => {
          // context.commit("set_user", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updateUser(context, { id, payload }) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/employee/${id}`, payload, {
          headers: {
            ...defaultHeaders,
            Authorization: `Bearer ${context.state.token}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getUser(context, { id }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/employee/${id}`, {
          headers: {
            ...defaultHeaders,
            Authorization: `Bearer ${context.state.token}`,
          },
        })
        .then((response) => {
          if (response) {
            context.commit("set_user", response.data.data.user);
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getPTO(context) {
    return new Promise((resolve, reject) => {
      axios
        .get("/time-entry/pto/all", {
          headers: {
            ...defaultHeaders,
            Authorization: `Bearer ${context.state.token}`,
          },
        })
        .then((response) => {
          if (response) {
            context.commit("set_pto", response.data.data);
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updatePTO(context, { id, payload }) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/time-entry/pto/${id}`, payload, {
          headers: {
            ...defaultHeaders,
            Authorization: `Bearer ${context.state.token}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createPTO(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/time-entry/pto/`, payload, {
          headers: {
            ...defaultHeaders,
            Authorization: `Bearer ${context.state.token}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updateLogo(context, { id, payload }) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/org/${id}/logo`, payload, {
          headers: {
            ...defaultHeaders,
            Authorization: `Bearer ${context.state.token}`,
          },
        })
        .then((response) => {
          // context.commit("set_user", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updatePicture(context, { id, payload }) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/employee/${id}/logo`, payload, {
          headers: {
            ...defaultHeaders,
            Authorization: `Bearer ${context.state.token}`,
          },
        })
        .then((response) => {
          // context.commit("set_user", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  setupWizardChange(context, data) {
    context.commit("set_setupWizard", data);
  },

  logout() {
    localStorage.removeItem("vuex");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("access_token");
    localStorage.removeItem("timerRunning");
    localStorage.removeItem("timerStartTime");
    localStorage.removeItem("timerPauseTime");
    router.push("/");
  },
};

const mutations = {
  set_user(state, data) {
    state.user = data;
  },
  set_verifyEmail(state, data) {
    state.verifyEmail = data;
  },
  set_token(state, data) {
    state.token = data;
  },
  set_refreshToken(state, data) {
    state.resetToken = data;
  },
  set_currencies(state, data) {
    state.currencies = data;
  },
  set_setupWizard(state, data) {
    state.user.org.setup_wizard_status = data;
  },
  set_plans(state, plans) {
    state.plans = plans;
  },
  set_industries(state, industries) {
    state.industries = industries;
  },
  setSelectedPlan(state, plan) {
    state.selectedPlan = plan;
  },
  set_url(state, url) {
    state.payment_url = url;
  },
  set_pto(state, data) {
    state.pto = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
