import axios from "axios";
import { authHeaders } from "@/store/config";
// import EventBus from "@/common/EventBus";

const state = {
  monthlyData: [],
  data: {},
  timekeeperCard: {},
  clientCard: {},
  matterCard: {},
};

const getters = {};

const actions = {
  getTimekeeper(context, data) {
    return new Promise((resolve, reject) => {
      const year = (data && data.year) || "";
      const type = (data && data.type) || "";
      const billable = (data && data.billable) || "";

      axios
        .get("/time-entry/timekeeper/dashboard", {
          params: {
            year: year,
            type: type,
            billable: billable,
          },
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          if (response) {
            context.commit("set_monthlydata", response.data.monthlyData);
            context.commit("set_data", response.data);
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getTimekeeperCard(context, data) {
    return new Promise((resolve, reject) => {
      const year = (data && data.year) || "";
      const month = (data && data.month) || "";

      axios
        .get("/time-entry/timekeeper/dashboard/timekeeper-card", {
          params: {
            year: year,
            month: month,
          },
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          if (response) {
            context.commit("set_timekeepercard", response.data);
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getClientCard(context, data) {
    return new Promise((resolve, reject) => {
      const year = (data && data.year) || "";
      const month = (data && data.month) || "";
      const id = (data && data.clientId) || "";

      axios
        .get("/time-entry/timekeeper/dashboard/client-metrics", {
          params: {
            month: month,
            clientId: id,
            year: year,
          },
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          if (response) {
            context.commit("set_clientcard", response.data);
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getMatterCard(context, data) {
    return new Promise((resolve, reject) => {
      const year = (data && data.year) || "";
      const month = (data && data.month) || "";
      const id = (data && data.matterId) || "";

      axios
        .get("/time-entry/timekeeper/dashboard/project-metrics", {
          params: {
            month: month,
            matterId: id,
            year: year,
          },
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          if (response) {
            context.commit("set_projectcard", response.data);
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  set_monthlydata(state, data) {
    state.monthlyData = data;
  },
  set_data(state, data) {
    state.data = data;
  },
  set_timekeepercard(state, data) {
    state.timekeeperCard = data;
  },
  set_clientcard(state, data) {
    state.clientCard = data;
  },
  set_projectcard(state, data) {
    state.matterCard = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
