// import router from '@/router';
import axios from "axios";
import { authHeaders } from "@/store/config";
import EventBus from "@/common/EventBus";

const state = {
  departments: [],
  departmentdata: {},
};

const getters = {};

const actions = {
  createDepartment(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/department/", data, {
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          context.commit("add_department_obj", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updateDepartment(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/department/${data.id}`, data.department, {
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  deleteDepartment(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/department/${data.id}`, {
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          if (response.data) {
            EventBus.dispatch("show-snackbar", {
              color: "success",
              message: response.data.message,
            });
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getAllDepartment(context, data) {
    return new Promise((resolve, reject) => {
      // Ensure 'data' is defined, and provide default values for 'search', 'pageNo', and 'pageSize'
      const search = (data && data.search) || "";  // Default search to empty string
      const pageNo = (data && data.pageNo) || 1;   // Default to page 1 if not provided
      const pageSize = (data && data.pageSize) || 10;  // Default to page size of 10 if not provided
  
      axios
        .get("/department/all", {
          params: {
            search: search,
            pageNo: pageNo,
            pageSize: pageSize,
          },
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          if (response) {
            context.commit("set_department", response.data.data);
            context.commit("set_data", response.data);
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },  
}

const mutations = {
  add_department_obj(state, data) {
    state.departments.push(data);
  },
  set_department(state, data) {
    state.departments = data;
  },
  set_data(state, data) {
    state.departmentdata = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
