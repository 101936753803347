// import router from '@/router';
import axios from "axios";
import { authHeaders } from "@/store/config";
import EventBus from "@/common/EventBus";

const state = {
  clients: [],
  clientdata: {},
  format: [],
};

const getters = {};

const actions = {
  createClient(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/client", data, {
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          context.commit("add_client_obj", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createBulkClient(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("client/bulk-upload", data, {
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          context.commit("add_client_obj", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getClients(context, data) {
    return new Promise((resolve, reject) => {
      // Ensure 'data' is defined, and provide default values for 'search', 'pageNo', and 'pageSize'
      const search = (data && data.search) || "";  // Default search to empty string
      const pageNo = (data && data.pageNo) || 1;   // Default to page 1 if not provided
      const pageSize = (data && data.pageSize) || 10;  // Default to page size of 10 if not provided
  
      axios
        .get("/client/all", {
          params: {
            search: search,
            pageNo: pageNo,
            pageSize: pageSize,
          },
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          if (response) {
            context.commit("set_clients", response.data.data);
            context.commit("set_data", response.data);
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getFormat(context, entityType) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/bulk-templates/entity`, {
          params: {
            entity_type: entityType,
          },
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          if (response) {
            context.commit("set_format", response.data);
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getMatterByClientId(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/client/${data.client_id}/matter`, {
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  deleteClient(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/client/${data.id}`, {
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          if (response.data) {
            EventBus.dispatch("show-snackbar", {
              color: "success",
              message: response.data.message,
            });
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updateClient(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/client/${data.id}`, data.client, {
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          if (response.data) {
            EventBus.dispatch("show-snackbar", {
              color: "success",
              message: response.data.message,
            });
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  add_client_obj(state, data) {
    state.clients.push(data);
  },
  set_clients(state, data) {
    state.clients = data;
  },
  set_data(state, data) {
    state.clientdata = data;
  },
  set_format(state, data) {
    state.format = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
